import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getProductInfo, Product } from 'utils/get-product-info'
import { useCookieContext } from '../context/CookieProvider'

const useProductInfo = (): Product[] => {
  const router = useRouter()
  const [productInfo, setProductInfo] = useState<Product[]>(null)
  const { cookieCountry } = useCookieContext()
  if (!cookieCountry) {
    return null
  }

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const listProductInfoData = await getProductInfo(router.locale, cookieCountry)
        setProductInfo(listProductInfoData)
      } catch {}
    }
    fetchProductInfo()
  }, [cookieCountry])

  return productInfo
}

export default useProductInfo
