import { Box } from 'atomic'
import { ThemeColors } from 'atomic/styles/theme.css'
import React from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import CreateCard from 'components/create_card/CreateCard'

type BooksCard = {
  title: string
  link: string
  color: keyof ThemeColors
  coverImage: string
  hoverImage: string
}

type PrintsCard = {
  title: string
  link: string
  color: keyof ThemeColors
  coverImage: string
  hoverImage: string
}

type CreateCardsProps = {
  photobooks: BooksCard
  prints: PrintsCard
} & BuilderProps

const CreateCards: React.FC<CreateCardsProps> = ({ photobooks, prints, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box
      display={'flex'}
      flexDirection={{ mobile: 'column', desktop: 'row' }}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      gap={'s'}
      marginX={'auto'}
      marginY={'none'}
      paddingX={'s'}
      style={{ width: '100%', maxWidth: 2240 }}
      {...cleanProps}
    >
      <CreateCard card={photobooks} cardType={'books'} />
      <CreateCard card={prints} cardType={'prints'} />
    </Box>
  )
}

export default CreateCards
