import { gql } from '@apollo/client'
import log from './logger'
import { getApolloClient } from './apollo-client'

export type Product = {
  PricePerPage: number
  additionalPricePerMattePage: number
  currency: string
  key: string
  name: string
  price: number
  subDescription: string
  type: string
  updated_at: string
}

export const isPrintProduct = (product: Product): boolean => product.key.toLowerCase().startsWith('card')
export const isBookProduct = (product: Product): boolean => !product.key.toLowerCase().startsWith('card')

export const getProductInfo = async (locale: string, country: string): Promise<Product[]> => {
  return await getStoreData(locale, country)
}

const getStoreData = async (locale: string, country: string): Promise<Product[]> => {
  const region = locale.substring(3)
  const language = region.toUpperCase() === 'CA' ? 'en_US' : `${locale.substring(0, 2)}_${region.toUpperCase()}`

  try {
    const client = await getApolloClient()
    const { data } = await client.query({
      fetchPolicy: 'cache-first',
      query: gql`
          query MyQuery {
            getShopdata(lang: ${language}, locale: "${country}") {
              products {
                PricePerPage
                additionalPricePerMattePage
                currency
                freePages
                key
                name
                price
                subDescription                
              }
              shipping_base_price {
                currency
                price
              }
            }
          }
        `,
    })
    return data.getShopdata.products
  } catch (error) {
    return []
    log.error(error)
  }
}
